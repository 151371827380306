* {
  outline: 0 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  border-radius: .25rem;
}

.header-image {
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
}
@media screen and (max-width: calc(15rem * 6.8)) {
  /* source images are width to height ratio 6.8 */
  .header-image {
    height: 15rem;
  }
}

.top-gap {
  margin-top: 1rem;
}
.top-gap-half {
  margin-top: .5rem;
}
.bottom-gap-half {
  margin-bottom: .5rem;
}

.input-light {
  background-color: white;
  color: black;
}
.input-dark {
  background-color: black;
  color: lightgray;
}
.login-input {
  border: .1rem solid gray;
  border-radius: 0 .5rem .5rem 0;
  display: inline-block;
  height: 1.75rem;
  left: calc(50% - 4rem);
  padding: 0 0 0 .5rem;
  position: absolute;
  top: 0;
  width: 15rem;
}

.tiny-text {
  font-size: .7rem;
  margin-bottom: .5rem;
  padding: 0
}
button.black-link-button {
  background-color: inherit;
  border: none;
  color: black;
  text-decoration: underline;
}
button.white-link-button {
  background-color: inherit;
  border: none;
  color: white;
  text-decoration: underline;
}
button.black-link-button:hover, button.white-link-button:hover {
  color: darkcyan;
}